.video-background {
  position: relative;
  height: 100vh;
  background-size: cover;
}

.video-background video {
  position: absolute;
  top: 55px;
  left: 0px;
  width: 100%;
  height: 93%;
  background-size: cover;
  object-fit: cover;
  z-index: -3;
}

.content {
  position: relative;
  z-index: 1;
}
.dimmed-video {
  opacity: 0.7; /* Adjust the opacity value as needed */
}
.animated-text {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
